<template lang="pug">
.om-tactic-advanced-recommendation-card(
  :class="{ 'om-wizard-brandkit': true, 'recommendation-2024': !learnMoreOpenUrl }"
  :data-track-property="`item:${getItemNameForTracking}`"
)
  .om-tactic-advanced-recommendation-card-preview
    .om-tactic-advanced-recommendation-card-preview-overlay(
      @click="learnMore(tactic, 'advanced-tactic-learnMore')"
      data-track-property="change:tactic.learn"
    )
      div {{ $t('learnMore') }}
    img(v-if="hasImage" :src="image")

  .om-tactic-advanced-recommendation-card-content
    .om-tactic-advanced-recommendation-card-content-name.cursor-pointer(
      :class="{ 'has-chip': tactic.chip }"
      @click="learnMore(tactic, 'advancedTactic-learnMore')"
      data-track-property="change:tactic.learn"
    ) {{ tactic.name[$i18n.locale] }}
      om-chip(v-if="tactic.chip" :color="tactic.chip.color") {{ tactic.chip.text }}
    .om-tactic-advanced-recommendation-card-content-targeting(
      v-html="usageText(tactic)"
      :class="{ 'no-min-height': !learnMoreOpenUrl }"
    )
    .om-tactic-advanced-recommendation-card-content-controls(v-if="learnMoreOpenUrl")
      .om-tactic-advanced-recommendation-card-learn-more.mt-2
        om-link(
          withIconRight
          primary
          @click="learnMore(tactic, 'advanced-tactic-learnMore')"
          data-track-property="change:tactic.learn"
        ) {{ $t('learnMore') }}
</template>

<script>
  import tacticsMixin from './tacticsMixin';

  export default {
    mixins: [tacticsMixin],
    props: {
      index: {
        type: Number,
        require: true,
      },
      tactic: {
        type: Object,
        require: true,
      },
      targetingCharacterLimit: {
        type: Number,
        default: 300,
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .om-tactic-advanced-recommendation-card
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    border-radius: 8px

    &-preview
      width: 100%
      position: relative
      transition: .3s
      z-index: 9
      border-radius: 8px 8px 0 0
      overflow: hidden
      display: flex
      align-items: center
      justify-content: center
      aspect-ratio: 157 / 89
      background-color: #FFF7F2
      padding: 1rem

      img
        height: 100%

      &-overlay
        position: absolute
        width: 100%
        height: 100%
        z-index: 999
        opacity: 0
        transition: .3s
        display: flex
        align-items: center
        justify-content: center
        background: rgba(80, 87, 99, 0.7)
        cursor: pointer
        font-size: 14px
        font-weight: 700
        color: white
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.5)
        overflow: hidden
        border-top-left-radius: 8px
        border-top-right-radius: 8px
      &:hover
        .om-tactic-advanced-recommendation-card-preview-overlay
          opacity: 1
          overflow: hidden

    &-content
      padding: 1rem 2rem 1.5rem

      &-name
        font-weight: 700
        font-size: 1.2rem
        line-height: 1.2
        margin-bottom: 0.5rem
        &:hover
          color: $om-orange-500
        &.has-chip
          .chip
            padding: 0
            &-content
              font-size: 1.25rem
              font-weight: 700
              line-height: 1.4
              padding: 0 .25rem
              color: $om-gray-50

      &-targeting
        color: $om-gray-700
        font-size: .9375rem
        line-height: 1.5
        min-height: 4.5rem
        &.no-min-height
          min-height: 2.8125rem

      &-controls
        display: flex
        margin-top: auto
        justify-content: flex-end
    &.recommendation-2024
      .om-tactic-advanced-recommendation-card
        &-preview
          background-color: #F8ECF3
        &-content
          &-name
            display: flex
            align-items: center
            min-height: 2.5rem
</style>

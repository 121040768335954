<template lang="pug">
mixin fixTactic
  .om-tactic-recommendation-card(
    :class="{ 'om-wizard-brandkit': true }"
    data-track-property="item:static-headline-ab-testing"
  )
    .om-tactic-recommendation-card-preview
      .om-tactic-recommendation-card-preview-overlay(
        @click="$emit('selectFixTactic')"
        data-track-property="change:tactic.select"
      )
        div {{ $t('onboarding.wizard.pages.recommendation.select') }}
      .fix-tactic-preview
        img(:src="require(`@/assets/admin/img/${fixTactic.image}`)")
    .om-tactic-recommendation-card-content
      .om-tactic-recommendation-card-content-top
        .om-tactic-recommendation-card-content-conversion(
          v-if="fixTactic.tags && fixTactic.tags.length"
        )
          om-chip.font-weight-bold.font-size-1.om-tactic-recommendation-card-content-conversion-value(
            v-for="(tag, index) in fixTactic.tags"
            :key="`tag-${index}`"
            :color="setTagColor(tag)"
          ) {{ tag[$i18n.locale] }}
        .om-tactic-recommendation-card-content-name.cursor-pointer(
          @click="$emit('selectFixTactic')"
        ) {{ fixTactic.name[$i18n.locale] }}
        .om-tactic-recommendation-card-content-targeting {{ usageText(fixTactic) }}
      .om-tactic-recommendation-card-content-controls(v-if="fixTactic.hasSelectHandler")
        om-link(
          withIconRight
          primary
          @click="openFixLink"
          data-track-property="change:tactic.learn"
        ) {{ $t('learnMore') }}
        .om-tactic-recommendation-card-content-controls-buttons
          om-button(
            primary
            large
            @click="$emit('selectFixTactic')"
            data-track-property="change:tactic.select"
          ) {{ $t('onboarding.wizard.pages.recommendation.select') }}

div(v-if="template || hasFixTactic")
  template(v-if="hasFixTactic")
    +fixTactic
  template(v-else)
    .om-tactic-recommendation-card(
      v-if="template && canShow"
      :class="{ 'om-wizard-brandkit': true }"
      :data-track-property="`item:${getItemNameForTracking}`"
    )
      .om-tactic-recommendation-card-preview.ssr
        .om-tactic-recommendation-card-preview-overlay(
          @click="showTemplatePreview()"
          data-track-property="change:tactic.select"
        )
          UilSearchPlus.om-tactic-recommendation-card-preview-icon
          div {{ $t('preview') }}
        template-frame.cursor-pointer(
          @observable="$emit('observable', $event)"
          allowSsr
          @inited="$emit('inited')"
          :dimensions="dimensions"
          :template="template"
          :color="color"
          @contentLoaded="onContentLoaded"
          @click.native="showTemplatePreview()"
          :themeKit="themeKit"
        )
          template(#fallback)
            template-box.cursor-pointer(
              :template="template"
              :uniqueColors="template.universal"
              :color="color"
              @click.native="showTemplatePreview()"
            )
      .om-tactic-recommendation-card-content
        .om-tactic-recommendation-card-content-top
          .om-tactic-recommendation-card-content-conversion(v-if="hasConversionInfo")
            om-chip.font-weight-bold.font-size-1.om-tactic-recommendation-card-content-conversion-value(
              color="forest"
            ) {{ useCase.name[$i18n.locale] }}
          .om-tactic-recommendation-card-content-name.cursor-pointer(
            @click="showTemplatePreview()"
            data-track-property="change:tactic.select"
          ) {{ subHeadline }}
          .om-tactic-recommendation-card-content-targeting {{ usageText(useCase) }}
        .om-tactic-recommendation-card-content-controls
          om-link(
            withIconRight
            primary
            @click="learnMore(useCase, 'wizard-recommendation-easy-learnMore')"
            data-track-property="change:tactic.learn"
          ) {{ $t('learnMore') }}
          .om-tactic-recommendation-card-content-controls-buttons
            om-button.mr-3(
              secondary
              large
              @click="goToSimilarTemplatesPage"
              data-track-property="change:tactic.showTemplates"
            ) {{ $t('showTemplates') }}
            om-button(
              primary
              large
              @click="showTemplatePreview()"
              data-track-property="change:tactic.select"
            ) {{ $t('onboarding.wizard.pages.recommendation.select') }}
</template>

<script>
  /*
  [Dzsi] IMPROVEMENT: it would be worth combing with the template chooser tactic card to eliminate duplications
  (packages/om-admin/src/components/TemplateChooser/components/cards/TacticCard.vue)
  */
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import TemplateBox from '@/components/Template/Themes/TemplateBox.vue';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import { multiColorThemes } from '@/views/Wizard/consts';
  import { UilSearchPlus } from '@iconscout/vue-unicons';
  import { mapGetters } from 'vuex';
  // import { getAbTestTactic } from '@/config/staticTactics';
  import { track } from '@/services/xray';
  import tacticsMixin from './tacticsMixin';

  export default {
    components: { TemplateBox, TemplateFrame, UilSearchPlus },
    mixins: [previewParentMixin, ssrMixin, tacticsMixin],

    props: {
      useCase: {
        type: Object,
        default: () => ({}),
      },
      theme: {
        type: String,
        default: '',
      },
      dimensions: {
        type: Object,
        default: () => ({
          width: null,
          height: null,
        }),
      },
      customTheme: {
        type: Object,
        default: null,
      },
      index: {
        type: Number,
        default: 0,
      },
      fixTactic: {
        type: Object,
        default: () => ({}),
      },
      targetingCharacterLimit: {
        type: Number,
        default: 300,
      },
    },

    data: () => ({ canShow: true }),

    computed: {
      ...mapGetters(['wizardPreferences']),

      getItemNameForTracking() {
        const url = this.useCase.content;
        const parts = (url || '').split('/');
        return parts[parts.length - 1];
      },

      subHeadline() {
        if (this.$i18n.locale === 'en') {
          return this.useCase.subHeadline;
        }
        return this.useCase.segment.hu;
      },

      templates() {
        const templates = this.useCase.templates
          .filter((t) => multiColorThemes.includes(t.theme))
          .map((t) => ({
            ...this.useCase.thumbnail,
            theme: t.theme,
            thumbnail: t.thumbnail,
            type: t.type,
            _id: t._id,
          }));
        const thumbnailId = this.useCase.thumbnail ? [this.useCase.thumbnail] : [];
        return [...thumbnailId, ...templates];
      },

      template() {
        if (this.hasFixTactic) return null;
        if (this.customTheme) {
          const templates = this.useCase.templates;
          const interstitial = this.useCase.templates.find(({ type }) =>
            ['fullscreen', 'interstitial'].includes(type),
          );
          const popup = interstitial || templates.find(({ type }) => type === 'popup');
          const sidemessage = popup || templates.find(({ type }) => type === 'sidebar');
          const nanobar = sidemessage || templates.find(({ type }) => type === 'nanobar');
          const template = nanobar || templates.find(({ type }) => type === 'embedded');
          return template || templates[0];
        }

        const template = this.useCase.templates.find((template) => template.theme === this.theme);

        return template || this.templates[0];
      },

      currentTheme() {
        return this.$route.query.theme;
      },
      themeKit() {
        return this.customTheme?.themeKit;
      },
      sourceThemeName() {
        return this.customTheme.sourceTheme?.name;
      },
      color() {
        const fallbackColor = this.template?.universal ? this.$route.query.color : null;
        return this.themeKit ? this.themeKit?.colors?.mainColor || fallbackColor : fallbackColor;
      },

      hasFixTactic() {
        return !!this.fixTactic?.name?.en;
      },

      hasConversionInfo() {
        if (!this.useCase.value1 || !this.useCase.value2) return false;
        // too long text indicates the assumption that the data here is not what we expect
        if (this.useCase.value1.length > 20) return false;
        return true;
      },

      hasFixTacticConversionInfo() {
        if (!this.fixTactic.value1 || !this.fixTactic.value2) return false;
        // too long text indicates the assumption that the data here is not what we expect
        if (this.fixTactic.value1.length > 20) return false;
        return true;
      },
    },

    mounted() {
      if (this.template) this.$emit('shown', this);
    },

    methods: {
      async goToSimilarTemplatesPage() {
        track('wizard-recommendation-easy-similar', { useCase: this.useCase });
        this.$emit('showSimilarTemplates', { useCase: this.useCase });
      },
      async showTemplatePreview() {
        track('wizard-recommendation-easy-preview', { useCase: this.useCase });
        this.$emit('showTemplatePreview', { useCase: this.useCase, template: this.template });
      },
      openFixLink() {
        window.open(this.fixTactic.content[this.$i18n.locale], '_blank');
      },
      setTagColor(tag) {
        return tag.gradient ? 'gradient' : 'forest';
      },
    },
  };
</script>

<style lang="sass">
  @import '@/sass/variables/_colors.sass'
  .font-size-1
    font-size: 1rem !important
  .font-weight-bold > div
    font-weight: inherit !important
  .om-tactic-recommendation-card
    display: flex
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
    border-radius: 8px
    margin-bottom: 40px
    position: relative
    background: #FDF7F4

    &-learn-more
      width: fit-content

    .om-tactic-recommended-icon
      position: absolute
      top: 0
      left: 0
      z-index: 10


    .fix-tactic-preview
      position: relative
      display: flex
      align-items: center
      justify-content: center
      aspect-ratio: 157 / 89
      background-color: #E3E5E8

      img
        height: 100%
        width: 100%


    &-preview
      width: 50%
      position: relative
      transition: .3s
      z-index: 9
      border-top-left-radius: 8px
      border-bottom-left-radius: 8px
      overflow: hidden

      &-overlay
        position: absolute
        width: 100%
        height: 100%
        z-index: 999
        opacity: 0
        transition: .3s
        display: flex
        align-items: center
        justify-content: center
        background: rgba(80, 87, 99, 0.7)
        cursor: pointer
        font-size: 14px
        font-weight: 700
        color: white
        text-shadow: 0 0 8px rgba(0, 0, 0, 0.5)
        overflow: hidden
        border-top-left-radius: 8px
        border-bottom-left-radius: 8px

        svg
          width: 20px
          height: 20px
          color: white
          margin-right: 6px
          filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.5))

      &:hover
        .om-tactic-recommendation-card-preview-overlay
          opacity: 1
          overflow: hidden
          border-top-left-radius: 8px
          border-bottom-left-radius: 8px

      .brand-themes-template-box
        width: 100%
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        @media screen and (min-width: 1921px)
          min-height: auto

    &-content
      flex: 1
      margin: 0 auto
      display: flex
      flex-direction: column
      padding: 1.5rem 2rem 1.5rem 2.5rem

      &-conversion
        display: flex
        margin-bottom: 1rem
        align-items: center
        font-size: 1rem
        color: $om-gray-700
        margin-left: -6px
        margin-right: -6px

      &-name
        font-weight: 700
        font-size: 1.5rem
        line-height: 1.35
        margin-bottom: 1rem
        &:hover
          color: $om-orange-500

      &-targeting
        color: $om-gray-700
        font-size: 1.125rem
        margin-bottom: 1rem
        line-height: 1.5

      &-controls
        margin-top: auto
        display: flex
        justify-content: space-between

        &-buttons
          display: flex
          justify-content: flex-end

    &:not(.om-wizard-brandkit)
      .om-tactic-recommendation-card
        &-content
          &-name
            font-size: 1.125rem
            line-height: 1.75rem
          &-targeting
            font-size: .875rem
            line-height: 1.25rem
</style>
